import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { FormComponentDemo } from '../../docs/helpers/FormComponentDemo';
import { Textarea, NotifiedTextarea } from '../index';
import { variants, sizes } from './helpers/variants';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Textarea" title="Textarea" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-textarea"
    }}>{`Basic textarea`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "textarea-with-rows-and-cols"
    }}>{`Textarea with rows and cols`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            cols={12}\n            onChange={(event, value) => console.log(value)}\n            rows={2}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea cols={12} onChange={(event, value) => console.log(value)} rows={2} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "textarea-with-max-length"
    }}>{`Textarea with max length`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            maxLength={12}\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea maxLength={12} onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-textarea"
    }}>{`Labeled textarea`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transitional-labeled-textarea"
    }}>{`Transitional labeled textarea`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textarea\">\n          <Textarea\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "labeled-textarea-with-placeholder"
    }}>{`Labeled textarea with placeholder`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo unwrapped property=\"value\" type=\"textarea\">\n          <Textarea\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            placeholder=\"Hello world\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea label="Super fantastic label" onChange={(event, value) => console.log(value)} placeholder="Hello world" variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "disabled-textarea"
    }}>{`Disabled textarea`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            disabled\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea disabled label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "read-only-textarea"
    }}>{`Read only textarea`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            readOnly\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea readOnly label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "danger-textarea"
    }}>{`Danger textarea`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"danger\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="danger" variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "warning-textarea"
    }}>{`Warning textarea`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"warning\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="warning" variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "success-textarea"
    }}>{`Success textarea`}</h2>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            validity=\"success\"\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} validity="success" variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-textarea"
    }}>{`Contrast textarea`}</h2>
    <Playground __position={12} __code={'<ThemeWrapper contrast>\n  <Looper\n    list={variants}\n    render={variant => (\n      <Rhythm py={3}>\n        <FormComponentDemo\n          contrast\n          unwrapped\n          initialValue=\"Hello world\"\n          property=\"value\"\n          type=\"textarea\"\n        >\n          <Textarea\n            contrast\n            transitional\n            label=\"Super fantastic label\"\n            onChange={(event, value) => console.log(value)}\n            variant={variant}\n          />\n        </FormComponentDemo>\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
          <FormComponentDemo contrast unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
            <Textarea contrast transitional label="Super fantastic label" onChange={(event, value) => console.log(value)} variant={variant} mdxType="Textarea" />
          </FormComponentDemo>
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "notifications"
    }}>{`Notifications`}</h2>
    <Playground __position={13} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'danger\', \'warning\', \'success\', \'primary\', \'neutral\']}\n    render={level => (\n      <Looper\n        list={variants}\n        render={variant => (\n          <Rhythm py={3}>\n            <FormComponentDemo\n              unwrapped\n              initialValue=\"Hello world\"\n              property=\"value\"\n              type=\"textarea\"\n            >\n              <NotifiedTextarea\n                level={level}\n                notification={`This is an example ${level} notification.`}\n                onChange={(event, value) => console.log(value)}\n                variant={variant}\n              />\n            </FormComponentDemo>\n          </Rhythm>\n        )}\n      />\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['danger', 'warning', 'success', 'primary', 'neutral']} render={level => <Looper list={variants} render={variant => <Rhythm py={3} mdxType="Rhythm">
              <FormComponentDemo unwrapped initialValue="Hello world" property="value" type="textarea" mdxType="FormComponentDemo">
                <NotifiedTextarea level={level} notification={`This is an example ${level} notification.`} onChange={(event, value) => console.log(value)} variant={variant} mdxType="NotifiedTextarea" />
              </FormComponentDemo>
            </Rhythm>} mdxType="Looper" />} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "sizes"
    }}>{`Sizes`}</h2>
    <Playground __position={14} __code={'<ThemeWrapper>\n  <Looper\n    list={sizes}\n    render={size => (\n      <Rhythm grouped my={3}>\n        <Looper\n          list={variants}\n          render={variant => (\n            <Rhythm key={variant} py={3}>\n              <FormComponentDemo unwrapped property=\"value\" type=\"textarea\">\n                <Textarea\n                  transitional\n                  label={`Size ${size}`}\n                  onChange={(event, value) => console.log(value)}\n                  size={size}\n                  variant={variant}\n                />\n              </FormComponentDemo>\n            </Rhythm>\n          )}\n        />\n      </Rhythm>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      FormComponentDemo,
      Textarea,
      NotifiedTextarea,
      variants,
      sizes,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={sizes} render={size => <Rhythm grouped my={3} mdxType="Rhythm">
          <Looper list={variants} render={variant => <Rhythm key={variant} py={3} mdxType="Rhythm">
                <FormComponentDemo unwrapped property="value" type="textarea" mdxType="FormComponentDemo">
                  <Textarea transitional label={`Size ${size}`} onChange={(event, value) => console.log(value)} size={size} variant={variant} mdxType="Textarea" />
                </FormComponentDemo>
              </Rhythm>} mdxType="Looper" />
        </Rhythm>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Textarea} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      